import React, { useEffect } from "react";
import Prism from "prismjs";
import SgLayout from "~layouts/sg-layout";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import Footer, { AboutBlurb, CallBlurb } from '~2-components/Footer/Footer';
import FooterKontent from '~3-data-components/FooterKontent';
import { dummyFooterData } from '~data/dummyFooterData';

const FooterEg = `import Footer from '~2-components/Footer/Footer';

<Footer
    callHeading ='' // Required
    aboutHeading ='' // Required
    facebookUrl ='' // Optional
    twitterUrl ='' // Optional
    linkedInUrl ='' // Optional
>
    <AboutBlurb>
        // Content goes here
    </AboutBlurb>
    <CallBlurb>
        // Content goes here
    </CallBlurb>
</Footer>
`;

const SgFooter = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Footer</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={FooterEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <Footer {...dummyFooterData}>
                <AboutBlurb>
                    <p>Adviceline Injury Lawyers is a division of Holding Redlich © 2019<br/>Level 8, 555 Bourke Street, Melbourne, 3000<br/>Read our&nbsp;<a href="/privacy-policy">privacy policy</a></p>
                </AboutBlurb>
                <CallBlurb>
                    <p>At Adviceline our lawyers answer the phone so that you receive free legal advice straight away. No Win, No Fee</p>
                </CallBlurb>
            </Footer>
            <section className="sg-content">
                <div className="container">
                    <h2>LIVE Kontent footer</h2>
                </div>
            </section>
            <FooterKontent />
        </SgLayout>
    );
};

export default SgFooter;
